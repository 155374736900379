import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'

import {ApiService} from '@web/core/services/api/api.service'
import {ProfileModel} from '@web/core/models/api/core/profile.model'
import {SKIP_LOADER_SHOWING_PARAMS} from '@web/core/utils/params'

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends ApiService {

  constructor(
    protected http: HttpClient,
  ) {
    super('profile')
  }

  fetch(): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(this.getUrl(), {params: SKIP_LOADER_SHOWING_PARAMS})
  }

  updateProfile(payload: ProfileModel): Observable<ProfileModel> {
    return this.http.put<ProfileModel>(this.getUrl(), payload)
  }

  updateAvatar(file: File): Observable<ProfileModel> {
    const payload = new FormData()
    payload.append('avatar', file)

    return this.http.put<ProfileModel>(this.getUrl('avatar'), payload)
  }
}
