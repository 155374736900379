import {Injectable} from '@angular/core'
import {Action, Selector, State, StateContext} from '@ngxs/store'

import {ProfileService} from '@web/core/services/api/profile.service'
import {ProfileModel} from '@web/core/models/api/core/profile.model'

import {GetProfile, UpdateAvatar, UpdateProfile} from './actions'

interface UserStateModel {
  profile: ProfileModel;
  totalPoints: number;
}

const defaults: UserStateModel = {
  profile: null,
  totalPoints: 0,
}

@State<UserStateModel>({
  name: 'user',
  defaults,
})
@Injectable()
export class UserState {

  @Selector()
  static profile(state: UserStateModel): ProfileModel {
    return state.profile
  }

  constructor(
    private profileService: ProfileService,
  ) {
  }

  @Action(GetProfile)
  GetProfile({patchState}: StateContext<UserStateModel>) {
    return this.profileService.fetch()
      .toPromise()
      .then(profile => {
        patchState({profile})
      })
  }

  @Action(UpdateProfile)
  UpdateProfile({patchState}: StateContext<UserStateModel>, {payload}: UpdateProfile) {
    return this.profileService.updateProfile(payload)
      .toPromise()
      .then(profile => {
        patchState({profile})
      })
  }

  @Action(UpdateAvatar)
  UpdateAvatar({patchState}: StateContext<UserStateModel>, {file}: UpdateAvatar) {
    return this.profileService.updateAvatar(file)
      .toPromise()
      .then(profile => {
        patchState({profile})
      })
  }
}
